import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ROAD,
    DELETE_ROAD,
    ROAD_FORM_TOOGLE_LOADING,
    SET_ROAD_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ROAD,
} from "../constants";
import { formatRoads } from "./settingsActionsUtils";

/* ROAD LIST */
export const fetchRoads = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.roads.list)
            .then((response) => {
                const roads = formatRoads(response.data);
                dispatch({
                    type: SET_ROAD_LIST,
                    payload: keyBy(roads, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ROADS
export const createRoad = (newRoad) => {
    return async (dispatch) => {
        dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.roads.create, newRoad)
            .then((response) => {
                const road = formatRoads(response.data);
                dispatch({ type: ADD_NEW_ROAD, payload: road });
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Ruta creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRoad = (updatedRoad) => {
    return async (dispatch) => {
        dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.roads.edit}${updatedRoad && updatedRoad._id}`, updatedRoad)
            .then((response) => {
                const road = formatRoads(response.data);
                dispatch({ type: UPDATE_ROAD, payload: road });
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ruta actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRoads = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.roads.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ROAD, payload: Ids });
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ruta eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROAD_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
