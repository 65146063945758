import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_DEPARTMENT,
    DELETE_DEPARTMENT,
    DEPARTMENT_FORM_TOOGLE_LOADING,
    SET_DEPARTMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_DEPARTMENT,
} from "../constants";
import { formatDepartments } from "./settingsActionsUtils";

/* DEPARTMENT LIST */
export const fetchDepartments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.departments.list)
            .then((response) => {
                const departments = formatDepartments(response.data);
                dispatch({
                    type: SET_DEPARTMENT_LIST,
                    payload: keyBy(departments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// DEPARTMENTS
export const createDepartment = (newDepartment) => {
    return async (dispatch) => {
        dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.departments.create, newDepartment)
            .then((response) => {
                const department = formatDepartments(response.data);
                dispatch({ type: ADD_NEW_DEPARTMENT, payload: department });
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Departamento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateDepartment = (updatedDepartment) => {
    return async (dispatch) => {
        dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.departments.edit}${updatedDepartment && updatedDepartment._id}`, updatedDepartment)
            .then((response) => {
                const department = formatDepartments(response.data);
                dispatch({ type: UPDATE_DEPARTMENT, payload: department });
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Departamento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteDepartments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.departments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_DEPARTMENT, payload: Ids });
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Departamento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
