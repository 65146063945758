export const API = {
	centers: {
    create: `/center`,
    edit: `/center/`,
    delete: `/center/`,
    list: `/center`,
  },
	tpvRequests: {
    create: `/tpvRequest`,
    edit: `/tpvRequest/`,
    delete: `/tpvRequest/`,
    list: `/tpvRequest`,
  },
	tpvs: {
    create: `/tpv`,
    edit: `/tpv/`,
    delete: `/tpv/`,
    list: `/tpv`,
  },
	roles: {
    create: `/role`,
    edit: `/role/`,
    delete: `/role/`,
    list: `/role`,
  },
	advertisements: {
    create: `/advertisement`,
    edit: `/advertisement/`,
    delete: `/advertisement/`,
    list: `/advertisement`,
  },
	requests: {
    create: `/request`,
    edit: `/request/`,
    delete: `/request/`,
    list: `/request`,
  },
	departments: {
    create: `/department`,
    edit: `/department/`,
    delete: `/department/`,
    list: `/department`,
  },
  configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
  orders: {
    create: `/order`,
    edit: `/order/`,
    delete: `/order/`,
    list: `/order`,
  },
  articles: {
    create: `/article`,
    edit: `/article/`,
    delete: `/article/`,
    list: `/article`,
  },
  events: {
    create: `/event`,
    edit: `/event/`,
    delete: `/event/`,
    list: `/event`,
  },
  workOrders: {
    create: `/workOrder`,
    edit: `/workOrder/`,
    delete: `/workOrder/`,
    list: `/workOrder`,
  },
  maintenances: {
    create: `/maintenance`,
    edit: `/maintenance/`,
    delete: `/maintenance/`,
    list: `/maintenance`,
  },
  holidays: {
    create: `/holiday`,
    edit: `/holiday/`,
    delete: `/holiday/`,
    list: `/holiday`,
  },
  petitions: {
    create: `/petition`,
    edit: `/petition/`,
    delete: `/petition/`,
    list: `/petition`,
  },
  singUps: {
    create: `/singUp`,
    edit: `/singUp/`,
    delete: `/singUp/`,
    list: `/singUp`,
  },
  equipments: {
    create: `/equipment`,
    edit: `/equipment/`,
    delete: `/equipment/`,
    list: `/equipment`,
  },
  shifts: {
    create: `/shift`,
    edit: `/shift/`,
    delete: `/shift/`,
    list: `/shift`,
  },
  permisions: {
    create: `/permision`,
    edit: `/permision/`,
    delete: `/permision/`,
    list: `/permision`,
  },
  absences: {
    create: `/absence`,
    edit: `/absence/`,
    delete: `/absence/`,
    list: `/absence`,
  },
  epiRequests: {
    create: `/epiRequest`,
    edit: `/epiRequest/`,
    delete: `/epiRequest/`,
    list: `/epiRequest`,
  },
  epis: {
    create: `/epi`,
    edit: `/epi/`,
    delete: `/epi/`,
    list: `/epi`,
  },
  expenses: {
    create: `/expense`,
    edit: `/expense/`,
    delete: `/expense/`,
    list: `/expense`,
  },
  roatSheets: {
    create: `/roatSheet`,
    edit: `/roatSheet/`,
    delete: `/roatSheet/`,
    list: `/roatSheet`,
  },
  roads: {
    create: `/road`,
    edit: `/road/`,
    delete: `/road/`,
    list: `/road`,
  },
  customers: {
    create: `/customer`,
    edit: `/customer/`,
    delete: `/customer/`,
    list: `/customer`,
    mail: `/customer/mail`,
  },
  vehicles: {
    create: `/vehicle`,
    edit: `/vehicle/`,
    delete: `/vehicle/`,
    list: `/vehicle`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
