import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PETITION,
    DELETE_PETITION,
    PETITION_FORM_TOOGLE_LOADING,
    SET_PETITION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PETITION,
} from "../constants";
import { formatPetitions } from "./settingsActionsUtils";

/* PETITION LIST */
export const fetchPetitions = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.petitions.list)
            .then((response) => {
                const petitions = formatPetitions(response.data);
                dispatch({
                    type: SET_PETITION_LIST,
                    payload: keyBy(petitions, "_id"),
                });
                response.data = keyBy(petitions, "_id");
                return response;
            })
            .catch((err) => err);
        return response;
    };
};

// PETITIONS
export const createPetition = (newPetition) => {
    return async (dispatch) => {
        dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.petitions.create, newPetition)
            .then((response) => {
                const petition = formatPetitions(response.data);
                dispatch({ type: ADD_NEW_PETITION, payload: petition });
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Petición creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePetition = (updatedPetition) => {
    return async (dispatch) => {
        dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.petitions.edit}${updatedPetition && updatedPetition._id}`, updatedPetition)
            .then((response) => {
                const petition = formatPetitions(response.data);
                dispatch({ type: UPDATE_PETITION, payload: petition });
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Petición actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePetitions = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.petitions.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PETITION, payload: Ids });
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Petición eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PETITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
