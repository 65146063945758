import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ORDER,
    DELETE_ORDER,
    ORDER_FORM_TOOGLE_LOADING,
    SET_ORDER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ORDER,
} from "../constants";
import { formatOrders } from "./settingsActionsUtils";

/* ORDER LIST */
export const fetchOrders = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.orders.list)
            .then((response) => {
                const orders = formatOrders(response.data);
                dispatch({
                    type: SET_ORDER_LIST,
                    payload: keyBy(orders, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ORDERS
export const createOrder = (newOrder) => {
    return async (dispatch) => {
        dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.orders.create, newOrder)
            .then((response) => {
                const order = formatOrders(response.data);
                dispatch({ type: ADD_NEW_ORDER, payload: order });
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Pedido creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateOrder = (updatedOrder) => {
    return async (dispatch) => {
        dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.orders.edit}${updatedOrder && updatedOrder._id}`, updatedOrder)
            .then((response) => {
                const order = formatOrders(response.data);
                dispatch({ type: UPDATE_ORDER, payload: order });
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Pedido actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteOrders = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.orders.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ORDER, payload: Ids });
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Pedido eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
