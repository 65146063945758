import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SINGUP,
    DELETE_SINGUP,
    SINGUP_FORM_TOOGLE_LOADING,
    SET_SINGUP_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SINGUP,
} from "../constants";
import { formatSingUps } from "./settingsActionsUtils";

/* SINGUP LIST */
export const fetchSingUps = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.singUps.list)
            .then((response) => {
                const singUps = formatSingUps(response.data);
                dispatch({
                    type: SET_SINGUP_LIST,
                    payload: keyBy(singUps, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SINGUPS
export const createSingUp = (newSingUp) => {
    return async (dispatch) => {
        dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.singUps.create, newSingUp)
            .then((response) => {
                const singUp = formatSingUps(response.data);
                dispatch({ type: ADD_NEW_SINGUP, payload: singUp });
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Fichaje creado con éxito.",
                    },
                });
                return response;
            })
            .catch((err) => {
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSingUp = (updatedSingUp) => {
    return async (dispatch) => {
        dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.singUps.edit}${updatedSingUp && updatedSingUp._id}`, updatedSingUp)
            .then((response) => {
                const singUp = formatSingUps(response.data);
                dispatch({ type: UPDATE_SINGUP, payload: singUp });
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Fichaje actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSingUps = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.singUps.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SINGUP, payload: Ids });
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Fichaje eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
