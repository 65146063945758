import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ROATSHEET,
    DELETE_ROATSHEET,
    ROATSHEET_FORM_TOOGLE_LOADING,
    SET_ROATSHEET_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ROATSHEET,
} from "../constants";
import { formatRoatSheets } from "./settingsActionsUtils";

/* ROATSHEET LIST */
export const fetchRoatSheets = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.roatSheets.list)
            .then((response) => {
                const roatSheets = formatRoatSheets(response.data);
                dispatch({
                    type: SET_ROATSHEET_LIST,
                    payload: keyBy(roatSheets, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ROATSHEETS
export const createRoatSheet = (newRoatSheet) => {
    return async (dispatch) => {
        dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.roatSheets.create, newRoatSheet)
            .then((response) => {
                const roatSheet = formatRoatSheets(response.data);
                dispatch({ type: ADD_NEW_ROATSHEET, payload: roatSheet });
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Hoja de ruta creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRoatSheet = (updatedRoatSheet) => {
    return async (dispatch) => {
        dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.roatSheets.edit}${updatedRoatSheet && updatedRoatSheet._id}`, updatedRoatSheet)
            .then((response) => {
                const roatSheet = formatRoatSheets(response.data);
                dispatch({ type: UPDATE_ROATSHEET, payload: roatSheet });
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de ruta actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRoatSheets = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.roatSheets.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ROATSHEET, payload: Ids });
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de ruta eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROATSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
