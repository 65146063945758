import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
    (state) => state.authorization,
    (authorization) => authorization
);
export const getCurrentRoute = createSelector(
    (state) => state.currentRoute,
    (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
    getCurrentRoute,
    (route) => route.title
);
export const getSideMenuState = createSelector(
    (state) => state.app,
    (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
    (state) => state.currentTheme,
    (currentTheme) => true
);

/* PROFILE */
export const getUserProfile = createSelector(
    (state) => state.profile,
    (profile) => profile
);
export const currentUserId = createSelector(
    (state) => state.profile,
    (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
    !profile ? null : profile.role
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
    (state) => state.notifications,
    (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
    (state) => state.users,
    (users) => users
);
export const getUsersForm = createSelector(
    (state) => state.usersForm,
    (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
    (state) => state.alerts,
    (alerts) => alerts
);
export const getAlertsForm = createSelector(
    (state) => state.alertsForm,
    (form) => form
);

/* VEHICLES */
export const getVehicles = createSelector(
    (state) => state.vehicles,
    (vehicles) => vehicles
);
export const getVehiclesForm = createSelector(
    (state) => state.vehiclesForm,
    (form) => form
);

/* CUSTOMERS */
export const getCustomers = createSelector(
    (state) => state.customers,
    (customers) => customers
);
export const getCustomersForm = createSelector(
    (state) => state.customersForm,
    (form) => form
);

/* ROADS */
export const getRoads = createSelector(
    (state) => state.roads,
    (roads) => roads
);
export const getRoadsForm = createSelector(
    (state) => state.roadsForm,
    (form) => form
);

/* ROATSHEETS */
export const getRoatSheets = createSelector(
    (state) => state.roatSheets,
    (roatSheets) => roatSheets
);
export const getRoatSheetsForm = createSelector(
    (state) => state.roatSheetsForm,
    (form) => form
);

/* EXPENSES */
export const getExpenses = createSelector(
    (state) => state.expenses,
    (expenses) => expenses
);
export const getExpensesForm = createSelector(
    (state) => state.expensesForm,
    (form) => form
);

/* EPIS */
export const getEpis = createSelector(
    (state) => state.epis,
    (epis) => epis
);
export const getEpisForm = createSelector(
    (state) => state.episForm,
    (form) => form
);

/* EPIREQUESTS */
export const getEpiRequests = createSelector(
    (state) => state.epiRequests,
    (epiRequests) => epiRequests
);
export const getEpiRequestsForm = createSelector(
    (state) => state.epiRequestsForm,
    (form) => form
);

/* ABSENCES */
export const getAbsences = createSelector(
    (state) => state.absences,
    (absences) => absences
);
export const getAbsencesForm = createSelector(
    (state) => state.absencesForm,
    (form) => form
);

/* PERMISIONS */
export const getPermisions = createSelector(
    (state) => state.permisions,
    (permisions) => permisions
);
export const getPermisionsForm = createSelector(
    (state) => state.permisionsForm,
    (form) => form
);

/* SHIFTS */
export const getShifts = createSelector(
    (state) => state.shifts,
    (shifts) => shifts
);
export const getShiftsForm = createSelector(
    (state) => state.shiftsForm,
    (form) => form
);

/* EQUIPMENTS */
export const getEquipments = createSelector(
    (state) => state.equipments,
    (equipments) => equipments
);
export const getEquipmentsForm = createSelector(
    (state) => state.equipmentsForm,
    (form) => form
);

/* SINGUPS */
export const getSingUps = createSelector(
    (state) => state.singUps,
    (singUps) => singUps
);
export const getSingUpsForm = createSelector(
    (state) => state.singUpsForm,
    (form) => form
);

/* PETITIONS */
export const getPetitions = createSelector(
    (state) => state.petitions,
    (petitions) => petitions
);
export const getPetitionsForm = createSelector(
    (state) => state.petitionsForm,
    (form) => form
);

/* HOLIDAYS */
export const getHolidays = createSelector(
    (state) => state.holidays,
    (holidays) => holidays
);
export const getHolidaysForm = createSelector(
    (state) => state.holidaysForm,
    (form) => form
);

/* MAINTENANCES */
export const getMaintenances = createSelector(
    (state) => state.maintenances,
    (maintenances) => maintenances
);
export const getMaintenancesForm = createSelector(
    (state) => state.maintenancesForm,
    (form) => form
);

/* WORKORDERS */
export const getWorkOrders = createSelector(
    (state) => state.workOrders,
    (workOrders) => workOrders
);
export const getWorkOrdersForm = createSelector(
    (state) => state.workOrdersForm,
    (form) => form
);

/* EVENTS */
export const getEvents = createSelector(
    (state) => state.events,
    (events) => events
);
export const getEventsForm = createSelector(
    (state) => state.eventsForm,
    (form) => form
);

/* ARTICLES */
export const getArticles = createSelector(
    (state) => state.articles,
    (articles) => articles
);
export const getArticlesForm = createSelector(
    (state) => state.articlesForm,
    (form) => form
);

/* ORDERS */
export const getOrders = createSelector(
    (state) => state.orders,
    (orders) => orders
);
export const getOrdersForm = createSelector(
    (state) => state.ordersForm,
    (form) => form
);

/* CONFIGURATIONS */
export const getConfigurations = createSelector(
    (state) => state.configurations,
    (configurations) => configurations
);
export const getConfigurationsForm = createSelector(
    (state) => state.configurationsForm,
    (form) => form
);

/* DEPARTMENTS */
export const getDepartments = createSelector(
    (state) => state.departments,
    (departments) => departments
);
export const getDepartmentsForm = createSelector(
    (state) => state.departmentsForm,
    (form) => form
);

/* REQUESTS */
export const getRequests = createSelector(
    (state) => state.requests,
    (requests) => requests
);
export const getRequestsForm = createSelector(
    (state) => state.requestsForm,
    (form) => form
);

/* ADVERTISEMENTS */
export const getAdvertisements = createSelector(
    (state) => state.advertisements,
    (advertisements) => advertisements
);
export const getAdvertisementsForm = createSelector(
    (state) => state.advertisementsForm,
    (form) => form
);

/* ROLES */
export const getRoles = createSelector(
    (state) => state.roles,
    (roles) => roles
);
export const getRolesForm = createSelector(
    (state) => state.rolesForm,
    (form) => form
);

/* TPVS */
export const getTpvs = createSelector(
    (state) => state.tpvs,
    (tpvs) => tpvs
);
export const getTpvsForm = createSelector(
    (state) => state.tpvsForm,
    (form) => form
);

/* TPVREQUESTS */
export const getTpvRequests = createSelector(
    (state) => state.tpvRequests,
    (tpvRequests) => tpvRequests
);
export const getTpvRequestsForm = createSelector(
    (state) => state.tpvRequestsForm,
    (form) => form
);

/* CENTERS */
export const getCenters = createSelector(
    (state) => state.centers,
    (centers) => centers
);
export const getCentersForm = createSelector(
    (state) => state.centersForm,
    (form) => form
);
