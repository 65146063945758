import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_WORKORDER,
    DELETE_WORKORDER,
    WORKORDER_FORM_TOOGLE_LOADING,
    SET_WORKORDER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_WORKORDER,
} from "../constants";
import { formatWorkOrders } from "./settingsActionsUtils";

/* WORKORDER LIST */
export const fetchWorkOrders = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.workOrders.list)
            .then((response) => {
                const workOrders = formatWorkOrders(response.data);
                dispatch({
                    type: SET_WORKORDER_LIST,
                    payload: keyBy(workOrders, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// WORKORDERS
export const createWorkOrder = (newWorkOrder) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.workOrders.create, newWorkOrder)
            .then((response) => {
                const workOrder = formatWorkOrders(response.data);
                dispatch({ type: ADD_NEW_WORKORDER, payload: workOrder });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Parte de trabajo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateWorkOrder = (updatedWorkOrder) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.workOrders.edit}${updatedWorkOrder && updatedWorkOrder._id}`, updatedWorkOrder)
            .then((response) => {
                const workOrder = formatWorkOrders(response.data);
                dispatch({ type: UPDATE_WORKORDER, payload: workOrder });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parte de trabajo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteWorkOrders = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.workOrders.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_WORKORDER, payload: Ids });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parte de trabajo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
