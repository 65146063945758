import { axios } from "../connection/ConnectionHandler";
import { fetchCenters } from "./centersActions";
import { fetchTpvRequests } from "./tpvRequestsActions";
import { fetchTpvs } from "./tpvsActions";
import { fetchRoles } from "./rolesActions";
import { fetchAdvertisements } from "./advertisementsActions";
import { fetchRequests } from "./requestsActions";
import { fetchDepartments } from "./departmentsActions";
import { fetchConfigurations } from "./configurationsActions";
import { fetchOrders } from "./ordersActions";
import { fetchArticles } from "./articlesActions";
import { fetchEvents } from "./eventsActions";
import { fetchWorkOrders } from "./workOrdersActions";
import { fetchMaintenances } from "./maintenancesActions";
import { fetchHolidays } from "./holidaysActions";
import { fetchPetitions } from "./petitionsActions";
import { fetchSingUps } from "./singUpsActions";
import { fetchEquipments } from "./equipmentsActions";
import { fetchShifts } from "./shiftsActions";
import { fetchPermisions } from "./permisionsActions";
import { fetchAbsences } from "./absencesActions";
import { fetchEpiRequests } from "./epiRequestsActions";
import { fetchEpis } from "./episActions";
import { fetchExpenses } from "./expensesActions";
import { fetchRoatSheets } from "./roatSheetsActions";
import { fetchRoads } from "./roadsActions";
import { fetchCustomers } from "./customersActions";
import { fetchVehicles } from "./vehiclesActions";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  SET_CURRENT_ROUTE,
  SET_PROFILE,
  USER_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchUsers } from "./usersActions";
import { routes } from "../constants/routes";
import { fetchAlerts } from "./alertsActions";

const rememberLogin = (username, password, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASS_COOKIE_NAME, password, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
  }
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
  return async (dispatch) => {
    const token = await getCookie(APP_COOKIE_NAME);
    const userId = await getCookie(USER_ID_COOKIE_NAME);
    if (token) {
      dispatch({ type: AUTH, payload: { auth: true, token, userId } });
    }
  };
};

export const fetchUserProfile = () => {
  return (dispatch) => {
    axios
      .get(API.users.profile)
      .then((response) =>
        dispatch({ type: SET_PROFILE, payload: response.data })
      )
      .catch((err) => err);
  };
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    await dispatch(fetchVehicles());
		dispatch(fetchCenters());
		dispatch(fetchTpvRequests());
		dispatch(fetchTpvs());
		dispatch(fetchRoles());
		dispatch(fetchAdvertisements());
		dispatch(fetchRequests());
		dispatch(fetchDepartments());
    await dispatch(fetchMaintenances());
    dispatch(fetchConfigurations());
    dispatch(fetchOrders());
    dispatch(fetchArticles());
    dispatch(fetchEvents());
    dispatch(fetchUsers());
    dispatch(fetchWorkOrders());
    dispatch(fetchHolidays());
    dispatch(fetchPetitions());
    dispatch(fetchSingUps());
    dispatch(fetchEquipments());
    dispatch(fetchShifts());
    dispatch(fetchPermisions());
    dispatch(fetchAbsences());
    dispatch(fetchEpiRequests());
    dispatch(fetchEpis());
    dispatch(fetchExpenses());
    dispatch(fetchRoatSheets());
    dispatch(fetchRoads());
    dispatch(fetchCustomers());
    dispatch(fetchAlerts());
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(username, password, remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const check2FA = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.check2FA, data)
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(data.username, data.password, data.remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
