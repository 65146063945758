import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ADVERTISEMENT,
    DELETE_ADVERTISEMENT,
    ADVERTISEMENT_FORM_TOOGLE_LOADING,
    SET_ADVERTISEMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ADVERTISEMENT,
} from "../constants";
import { formatAdvertisements } from "./settingsActionsUtils";

/* ADVERTISEMENT LIST */
export const fetchAdvertisements = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.advertisements.list)
            .then((response) => {
                const advertisements = formatAdvertisements(response.data);
                dispatch({
                    type: SET_ADVERTISEMENT_LIST,
                    payload: keyBy(advertisements, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ADVERTISEMENTS
export const createAdvertisement = (newAdvertisement) => {
    return async (dispatch) => {
        dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.advertisements.create, newAdvertisement)
            .then((response) => {
                const advertisement = formatAdvertisements(response.data);
                dispatch({ type: ADD_NEW_ADVERTISEMENT, payload: advertisement });
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Anuncio creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateAdvertisement = (updatedAdvertisement) => {
    return async (dispatch) => {
        dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.advertisements.edit}${updatedAdvertisement && updatedAdvertisement._id}`, updatedAdvertisement)
            .then((response) => {
                const advertisement = formatAdvertisements(response.data);
                dispatch({ type: UPDATE_ADVERTISEMENT, payload: advertisement });
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Anuncio actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteAdvertisements = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.advertisements.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ADVERTISEMENT, payload: Ids });
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Anuncio eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADVERTISEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
