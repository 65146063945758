/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */
export const MAPS_API = "AIzaSyDqm62_JJvnvLK6ob9UKY1oQ9LTx0ul_VA";

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* CENTER */
export const SET_CENTER_LIST = "SET_CENTER_LIST";
export const ADD_NEW_CENTER = "ADD_NEW_CENTER";
export const UPDATE_CENTER = "UPDATE_CENTER";
export const DELETE_CENTER = "DELETE_CENTER";
export const OPEN_CENTER_FORM = "OPEN_CENTER_FORM";
export const CLOSE_CENTER_FORM = "CLOSE_CENTER_FORM";
export const EDIT_SELECTED_CENTER = "EDIT_SELECTED_CENTER";
export const CENTER_FORM_TOOGLE_LOADING = "CENTER_FORM_TOOGLE_LOADING";
/* <---- CENTER ----> */

/* TPVREQUEST */
export const SET_TPVREQUEST_LIST = "SET_TPVREQUEST_LIST";
export const ADD_NEW_TPVREQUEST = "ADD_NEW_TPVREQUEST";
export const UPDATE_TPVREQUEST = "UPDATE_TPVREQUEST";
export const DELETE_TPVREQUEST = "DELETE_TPVREQUEST";
export const OPEN_TPVREQUEST_FORM = "OPEN_TPVREQUEST_FORM";
export const CLOSE_TPVREQUEST_FORM = "CLOSE_TPVREQUEST_FORM";
export const EDIT_SELECTED_TPVREQUEST = "EDIT_SELECTED_TPVREQUEST";
export const TPVREQUEST_FORM_TOOGLE_LOADING = "TPVREQUEST_FORM_TOOGLE_LOADING";
/* <---- TPVREQUEST ----> */

/* TPV */
export const SET_TPV_LIST = "SET_TPV_LIST";
export const ADD_NEW_TPV = "ADD_NEW_TPV";
export const UPDATE_TPV = "UPDATE_TPV";
export const DELETE_TPV = "DELETE_TPV";
export const OPEN_TPV_FORM = "OPEN_TPV_FORM";
export const CLOSE_TPV_FORM = "CLOSE_TPV_FORM";
export const EDIT_SELECTED_TPV = "EDIT_SELECTED_TPV";
export const TPV_FORM_TOOGLE_LOADING = "TPV_FORM_TOOGLE_LOADING";
/* <---- TPV ----> */

/* ROLE */
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const OPEN_ROLE_FORM = "OPEN_ROLE_FORM";
export const CLOSE_ROLE_FORM = "CLOSE_ROLE_FORM";
export const EDIT_SELECTED_ROLE = "EDIT_SELECTED_ROLE";
export const ROLE_FORM_TOOGLE_LOADING = "ROLE_FORM_TOOGLE_LOADING";
/* <---- ROLE ----> */

/* ADVERTISEMENT */
export const SET_ADVERTISEMENT_LIST = "SET_ADVERTISEMENT_LIST";
export const ADD_NEW_ADVERTISEMENT = "ADD_NEW_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const OPEN_ADVERTISEMENT_FORM = "OPEN_ADVERTISEMENT_FORM";
export const CLOSE_ADVERTISEMENT_FORM = "CLOSE_ADVERTISEMENT_FORM";
export const EDIT_SELECTED_ADVERTISEMENT = "EDIT_SELECTED_ADVERTISEMENT";
export const ADVERTISEMENT_FORM_TOOGLE_LOADING = "ADVERTISEMENT_FORM_TOOGLE_LOADING";
/* <---- ADVERTISEMENT ----> */

/* REQUEST */
export const SET_REQUEST_LIST = "SET_REQUEST_LIST";
export const ADD_NEW_REQUEST = "ADD_NEW_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const OPEN_REQUEST_FORM = "OPEN_REQUEST_FORM";
export const CLOSE_REQUEST_FORM = "CLOSE_REQUEST_FORM";
export const EDIT_SELECTED_REQUEST = "EDIT_SELECTED_REQUEST";
export const REQUEST_FORM_TOOGLE_LOADING = "REQUEST_FORM_TOOGLE_LOADING";
/* <---- REQUEST ----> */

/* DEPARTMENT */
export const SET_DEPARTMENT_LIST = "SET_DEPARTMENT_LIST";
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const OPEN_DEPARTMENT_FORM = "OPEN_DEPARTMENT_FORM";
export const CLOSE_DEPARTMENT_FORM = "CLOSE_DEPARTMENT_FORM";
export const EDIT_SELECTED_DEPARTMENT = "EDIT_SELECTED_DEPARTMENT";
export const DEPARTMENT_FORM_TOOGLE_LOADING = "DEPARTMENT_FORM_TOOGLE_LOADING";
/* <---- DEPARTMENT ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* ORDER */
export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const OPEN_ORDER_FORM = "OPEN_ORDER_FORM";
export const CLOSE_ORDER_FORM = "CLOSE_ORDER_FORM";
export const EDIT_SELECTED_ORDER = "EDIT_SELECTED_ORDER";
export const ORDER_FORM_TOOGLE_LOADING = "ORDER_FORM_TOOGLE_LOADING";
/* <---- ORDER ----> */

/* ARTICLE */
export const SET_ARTICLE_LIST = "SET_ARTICLE_LIST";
export const ADD_NEW_ARTICLE = "ADD_NEW_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const OPEN_ARTICLE_FORM = "OPEN_ARTICLE_FORM";
export const CLOSE_ARTICLE_FORM = "CLOSE_ARTICLE_FORM";
export const EDIT_SELECTED_ARTICLE = "EDIT_SELECTED_ARTICLE";
export const ARTICLE_FORM_TOOGLE_LOADING = "ARTICLE_FORM_TOOGLE_LOADING";
/* <---- ARTICLE ----> */

/* EVENT */
export const SET_EVENT_LIST = "SET_EVENT_LIST";
export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const OPEN_EVENT_FORM = "OPEN_EVENT_FORM";
export const CLOSE_EVENT_FORM = "CLOSE_EVENT_FORM";
export const EDIT_SELECTED_EVENT = "EDIT_SELECTED_EVENT";
export const EVENT_FORM_TOOGLE_LOADING = "EVENT_FORM_TOOGLE_LOADING";
/* <---- EVENT ----> */

/* WORKORDER */
export const SET_WORKORDER_LIST = "SET_WORKORDER_LIST";
export const ADD_NEW_WORKORDER = "ADD_NEW_WORKORDER";
export const UPDATE_WORKORDER = "UPDATE_WORKORDER";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDER_FORM = "OPEN_WORKORDER_FORM";
export const CLOSE_WORKORDER_FORM = "CLOSE_WORKORDER_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
export const WORKORDER_FORM_TOOGLE_LOADING = "WORKORDER_FORM_TOOGLE_LOADING";
/* <---- WORKORDER ----> */

/* MAINTENANCE */
export const SET_MAINTENANCE_LIST = "SET_MAINTENANCE_LIST";
export const ADD_NEW_MAINTENANCE = "ADD_NEW_MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const OPEN_MAINTENANCE_FORM = "OPEN_MAINTENANCE_FORM";
export const CLOSE_MAINTENANCE_FORM = "CLOSE_MAINTENANCE_FORM";
export const EDIT_SELECTED_MAINTENANCE = "EDIT_SELECTED_MAINTENANCE";
export const MAINTENANCE_FORM_TOOGLE_LOADING = "MAINTENANCE_FORM_TOOGLE_LOADING";
/* <---- MAINTENANCE ----> */

/* HOLIDAY */
export const SET_HOLIDAY_LIST = "SET_HOLIDAY_LIST";
export const ADD_NEW_HOLIDAY = "ADD_NEW_HOLIDAY";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const OPEN_HOLIDAY_FORM = "OPEN_HOLIDAY_FORM";
export const CLOSE_HOLIDAY_FORM = "CLOSE_HOLIDAY_FORM";
export const EDIT_SELECTED_HOLIDAY = "EDIT_SELECTED_HOLIDAY";
export const HOLIDAY_FORM_TOOGLE_LOADING = "HOLIDAY_FORM_TOOGLE_LOADING";
/* <---- HOLIDAY ----> */

/* PETITION */
export const SET_PETITION_LIST = "SET_PETITION_LIST";
export const ADD_NEW_PETITION = "ADD_NEW_PETITION";
export const UPDATE_PETITION = "UPDATE_PETITION";
export const DELETE_PETITION = "DELETE_PETITION";
export const OPEN_PETITION_FORM = "OPEN_PETITION_FORM";
export const CLOSE_PETITION_FORM = "CLOSE_PETITION_FORM";
export const EDIT_SELECTED_PETITION = "EDIT_SELECTED_PETITION";
export const PETITION_FORM_TOOGLE_LOADING = "PETITION_FORM_TOOGLE_LOADING";
/* <---- PETITION ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* SHIFT */
export const SET_SHIFT_LIST = "SET_SHIFT_LIST";
export const ADD_NEW_SHIFT = "ADD_NEW_SHIFT";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const OPEN_SHIFT_FORM = "OPEN_SHIFT_FORM";
export const CLOSE_SHIFT_FORM = "CLOSE_SHIFT_FORM";
export const EDIT_SELECTED_SHIFT = "EDIT_SELECTED_SHIFT";
export const SHIFT_FORM_TOOGLE_LOADING = "SHIFT_FORM_TOOGLE_LOADING";
/* <---- SHIFT ----> */

/* PERMISION */
export const SET_PERMISION_LIST = "SET_PERMISION_LIST";
export const ADD_NEW_PERMISION = "ADD_NEW_PERMISION";
export const UPDATE_PERMISION = "UPDATE_PERMISION";
export const DELETE_PERMISION = "DELETE_PERMISION";
export const OPEN_PERMISION_FORM = "OPEN_PERMISION_FORM";
export const CLOSE_PERMISION_FORM = "CLOSE_PERMISION_FORM";
export const EDIT_SELECTED_PERMISION = "EDIT_SELECTED_PERMISION";
export const PERMISION_FORM_TOOGLE_LOADING = "PERMISION_FORM_TOOGLE_LOADING";
/* <---- PERMISION ----> */

/* ABSENCE */
export const SET_ABSENCE_LIST = "SET_ABSENCE_LIST";
export const ADD_NEW_ABSENCE = "ADD_NEW_ABSENCE";
export const UPDATE_ABSENCE = "UPDATE_ABSENCE";
export const DELETE_ABSENCE = "DELETE_ABSENCE";
export const OPEN_ABSENCE_FORM = "OPEN_ABSENCE_FORM";
export const CLOSE_ABSENCE_FORM = "CLOSE_ABSENCE_FORM";
export const EDIT_SELECTED_ABSENCE = "EDIT_SELECTED_ABSENCE";
export const ABSENCE_FORM_TOOGLE_LOADING = "ABSENCE_FORM_TOOGLE_LOADING";
/* <---- ABSENCE ----> */

/* EPIREQUEST */
export const SET_EPIREQUEST_LIST = "SET_EPIREQUEST_LIST";
export const ADD_NEW_EPIREQUEST = "ADD_NEW_EPIREQUEST";
export const UPDATE_EPIREQUEST = "UPDATE_EPIREQUEST";
export const DELETE_EPIREQUEST = "DELETE_EPIREQUEST";
export const OPEN_EPIREQUEST_FORM = "OPEN_EPIREQUEST_FORM";
export const CLOSE_EPIREQUEST_FORM = "CLOSE_EPIREQUEST_FORM";
export const EDIT_SELECTED_EPIREQUEST = "EDIT_SELECTED_EPIREQUEST";
export const EPIREQUEST_FORM_TOOGLE_LOADING = "EPIREQUEST_FORM_TOOGLE_LOADING";
/* <---- EPIREQUEST ----> */

/* EPI */
export const SET_EPI_LIST = "SET_EPI_LIST";
export const ADD_NEW_EPI = "ADD_NEW_EPI";
export const UPDATE_EPI = "UPDATE_EPI";
export const DELETE_EPI = "DELETE_EPI";
export const OPEN_EPI_FORM = "OPEN_EPI_FORM";
export const CLOSE_EPI_FORM = "CLOSE_EPI_FORM";
export const EDIT_SELECTED_EPI = "EDIT_SELECTED_EPI";
export const EPI_FORM_TOOGLE_LOADING = "EPI_FORM_TOOGLE_LOADING";
/* <---- EPI ----> */

/* EXPENSE */
export const SET_EXPENSE_LIST = "SET_EXPENSE_LIST";
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const OPEN_EXPENSE_FORM = "OPEN_EXPENSE_FORM";
export const CLOSE_EXPENSE_FORM = "CLOSE_EXPENSE_FORM";
export const EDIT_SELECTED_EXPENSE = "EDIT_SELECTED_EXPENSE";
export const EXPENSE_FORM_TOOGLE_LOADING = "EXPENSE_FORM_TOOGLE_LOADING";
/* <---- EXPENSE ----> */

/* ROATSHEET */
export const SET_ROATSHEET_LIST = "SET_ROATSHEET_LIST";
export const ADD_NEW_ROATSHEET = "ADD_NEW_ROATSHEET";
export const UPDATE_ROATSHEET = "UPDATE_ROATSHEET";
export const DELETE_ROATSHEET = "DELETE_ROATSHEET";
export const OPEN_ROATSHEET_FORM = "OPEN_ROATSHEET_FORM";
export const CLOSE_ROATSHEET_FORM = "CLOSE_ROATSHEET_FORM";
export const EDIT_SELECTED_ROATSHEET = "EDIT_SELECTED_ROATSHEET";
export const ROATSHEET_FORM_TOOGLE_LOADING = "ROATSHEET_FORM_TOOGLE_LOADING";
/* <---- ROATSHEET ----> */

/* ROAD */
export const SET_ROAD_LIST = "SET_ROAD_LIST";
export const ADD_NEW_ROAD = "ADD_NEW_ROAD";
export const UPDATE_ROAD = "UPDATE_ROAD";
export const DELETE_ROAD = "DELETE_ROAD";
export const OPEN_ROAD_FORM = "OPEN_ROAD_FORM";
export const CLOSE_ROAD_FORM = "CLOSE_ROAD_FORM";
export const EDIT_SELECTED_ROAD = "EDIT_SELECTED_ROAD";
export const ROAD_FORM_TOOGLE_LOADING = "ROAD_FORM_TOOGLE_LOADING";
/* <---- ROAD ----> */

/* CUSTOMER */
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const OPEN_CUSTOMER_FORM = "OPEN_CUSTOMER_FORM";
export const CLOSE_CUSTOMER_FORM = "CLOSE_CUSTOMER_FORM";
export const EDIT_SELECTED_CUSTOMER = "EDIT_SELECTED_CUSTOMER";
export const CUSTOMER_FORM_TOOGLE_LOADING = "CUSTOMER_FORM_TOOGLE_LOADING";
/* <---- CUSTOMER ----> */

/* VEHICLE */
export const SET_VEHICLE_LIST = "SET_VEHICLE_LIST";
export const ADD_NEW_VEHICLE = "ADD_NEW_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const OPEN_VEHICLE_FORM = "OPEN_VEHICLE_FORM";
export const CLOSE_VEHICLE_FORM = "CLOSE_VEHICLE_FORM";
export const EDIT_SELECTED_VEHICLE = "EDIT_SELECTED_VEHICLE";
export const VEHICLE_FORM_TOOGLE_LOADING = "VEHICLE_FORM_TOOGLE_LOADING";
/* <---- VEHICLE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
