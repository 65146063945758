import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CENTER,
    DELETE_CENTER,
    CENTER_FORM_TOOGLE_LOADING,
    SET_CENTER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CENTER,
} from "../constants";
import { formatCenters } from "./settingsActionsUtils";

/* CENTER LIST */
export const fetchCenters = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.centers.list)
            .then((response) => {
                const centers = formatCenters(response.data);
                dispatch({
                    type: SET_CENTER_LIST,
                    payload: keyBy(centers, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CENTERS
export const createCenter = (newCenter) => {
    return async (dispatch) => {
        dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.centers.create, newCenter)
            .then((response) => {
                const center = formatCenters(response.data);
                dispatch({ type: ADD_NEW_CENTER, payload: center });
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Centro creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCenter = (updatedCenter) => {
    return async (dispatch) => {
        dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.centers.edit}${updatedCenter && updatedCenter._id}`, updatedCenter)
            .then((response) => {
                const center = formatCenters(response.data);
                dispatch({ type: UPDATE_CENTER, payload: center });
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Centro actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCenters = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.centers.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CENTER, payload: Ids });
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Centro eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CENTER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
