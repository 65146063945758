import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_TPVREQUEST,
    DELETE_TPVREQUEST,
    TPVREQUEST_FORM_TOOGLE_LOADING,
    SET_TPVREQUEST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_TPVREQUEST,
} from "../constants";
import { formatTpvRequests } from "./settingsActionsUtils";

/* TPVREQUEST LIST */
export const fetchTpvRequests = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.tpvRequests.list)
            .then((response) => {
                const tpvRequests = formatTpvRequests(response.data);
                dispatch({
                    type: SET_TPVREQUEST_LIST,
                    payload: keyBy(tpvRequests, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// TPVREQUESTS
export const createTpvRequest = (newTpvRequest) => {
    return async (dispatch) => {
        dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.tpvRequests.create, newTpvRequest)
            .then((response) => {
                const tpvRequest = formatTpvRequests(response.data);
                dispatch({ type: ADD_NEW_TPVREQUEST, payload: tpvRequest });
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Recogida TPV creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateTpvRequest = (updatedTpvRequest) => {
    return async (dispatch) => {
        dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.tpvRequests.edit}${updatedTpvRequest && updatedTpvRequest._id}`, updatedTpvRequest)
            .then((response) => {
                const tpvRequest = formatTpvRequests(response.data);
                dispatch({ type: UPDATE_TPVREQUEST, payload: tpvRequest });
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Recogida TPV actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteTpvRequests = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.tpvRequests.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_TPVREQUEST, payload: Ids });
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Recogida TPV eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TPVREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
