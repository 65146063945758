import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SHIFT,
    DELETE_SHIFT,
    SHIFT_FORM_TOOGLE_LOADING,
    SET_SHIFT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SHIFT,
} from "../constants";
import { formatShifts } from "./settingsActionsUtils";

/* SHIFT LIST */
export const fetchShifts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.shifts.list)
            .then((response) => {
                const shifts = formatShifts(response.data);
                dispatch({
                    type: SET_SHIFT_LIST,
                    payload: keyBy(shifts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SHIFTS
export const createShift = (newShift) => {
    return async (dispatch) => {
        dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.shifts.create, newShift)
            .then((response) => {
                const shift = formatShifts(response.data);
                dispatch({ type: ADD_NEW_SHIFT, payload: shift });
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Turno creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateShift = (updatedShift) => {
    return async (dispatch) => {
        dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.shifts.edit}${updatedShift && updatedShift._id}`, updatedShift)
            .then((response) => {
                const shift = formatShifts(response.data);
                dispatch({ type: UPDATE_SHIFT, payload: shift });
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Turno actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteShifts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.shifts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SHIFT, payload: Ids });
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Turno eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SHIFT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
