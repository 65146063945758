import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ABSENCE,
    DELETE_ABSENCE,
    ABSENCE_FORM_TOOGLE_LOADING,
    SET_ABSENCE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ABSENCE,
} from "../constants";
import { formatAbsences } from "./settingsActionsUtils";

/* ABSENCE LIST */
export const fetchAbsences = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.absences.list)
            .then((response) => {
                const absences = formatAbsences(response.data);
                dispatch({
                    type: SET_ABSENCE_LIST,
                    payload: keyBy(absences, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ABSENCES
export const createAbsence = (newAbsence) => {
    return async (dispatch) => {
        dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.absences.create, newAbsence)
            .then((response) => {
                const absence = formatAbsences(response.data);
                dispatch({ type: ADD_NEW_ABSENCE, payload: absence });
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Baja médica creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateAbsence = (updatedAbsence) => {
    return async (dispatch) => {
        dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.absences.edit}${updatedAbsence && updatedAbsence._id}`, updatedAbsence)
            .then((response) => {
                const absence = formatAbsences(response.data);
                dispatch({ type: UPDATE_ABSENCE, payload: absence });
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Baja médica actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteAbsences = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.absences.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ABSENCE, payload: Ids });
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Baja médica eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ABSENCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
