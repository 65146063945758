import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_HOLIDAY,
    DELETE_HOLIDAY,
    HOLIDAY_FORM_TOOGLE_LOADING,
    SET_HOLIDAY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_HOLIDAY,
} from "../constants";
import { formatHolidays } from "./settingsActionsUtils";

/* HOLIDAY LIST */
export const fetchHolidays = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.holidays.list)
            .then((response) => {
                const holidays = formatHolidays(response.data);
                dispatch({
                    type: SET_HOLIDAY_LIST,
                    payload: keyBy(holidays, "_id"),
                });
                response.data = keyBy(holidays, "_id");
                return response;
            })
            .catch((err) => err);
        return response;
    };
};

// HOLIDAYS
export const createHoliday = (newHoliday) => {
    return async (dispatch) => {
        dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.holidays.create, newHoliday)
            .then((response) => {
                const holiday = formatHolidays(response.data);
                dispatch({ type: ADD_NEW_HOLIDAY, payload: holiday });
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Vacaciones creadas con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateHoliday = (updatedHoliday) => {
    return async (dispatch) => {
        dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.holidays.edit}${updatedHoliday && updatedHoliday._id}`, updatedHoliday)
            .then((response) => {
                const holiday = formatHolidays(response.data);
                dispatch({ type: UPDATE_HOLIDAY, payload: holiday });
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Vacaciones actualizadas.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteHolidays = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.holidays.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_HOLIDAY, payload: Ids });
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Vacaciones eliminadas.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOLIDAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const fetchPalmaHolidays = async (year) => {
    try {
        // Obtenemos los festivos de España
        const response = await axios.get(`https://date.nager.at/api/v3/PublicHolidays/${year}/ES`);

        // Filtramos los festivos relevantes para Palma de Mallorca
        const palmaHolidays = response.data.filter(holiday => {
            // Festivos nacionales
            if (holiday.global) return true;

            // Festivos específicos de las Islas Baleares
            if (holiday.counties && holiday.counties.includes('ES-IB')) return true;

            // Festivos específicos de Palma de Mallorca
            const palmaSpecificHolidays = [
                '01-20', // San Sebastián (patrón de Palma)
                '04-18', // Lunes de Pascua (fecha puede variar, ajustar según el año)
                '12-26', // Segunda fiesta de Navidad
                '12-31'  // San Silvestre (Nochevieja)
            ];

            return palmaSpecificHolidays.includes(holiday.date.slice(5));
        });

        return palmaHolidays.map(holiday => ({
            date: holiday.date,
            localName: holiday.localName,
            name: holiday.name,
            type: holiday.type
        }));
    } catch (error) {
        console.error('Error fetching Palma holidays:', error);
        return [];
    }
};