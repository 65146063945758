import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ARTICLE,
    DELETE_ARTICLE,
    ARTICLE_FORM_TOOGLE_LOADING,
    SET_ARTICLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ARTICLE,
} from "../constants";
import { formatArticles } from "./settingsActionsUtils";

/* ARTICLE LIST */
export const fetchArticles = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.articles.list)
            .then((response) => {
                const articles = formatArticles(response.data);
                dispatch({
                    type: SET_ARTICLE_LIST,
                    payload: keyBy(articles, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ARTICLES
export const createArticle = (newArticle) => {
    return async (dispatch) => {
        dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.articles.create, newArticle)
            .then((response) => {
                const article = formatArticles(response.data);
                dispatch({ type: ADD_NEW_ARTICLE, payload: article });
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Artículo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateArticle = (updatedArticle) => {
    return async (dispatch) => {
        dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.articles.edit}${updatedArticle && updatedArticle._id}`, updatedArticle)
            .then((response) => {
                const article = formatArticles(response.data);
                dispatch({ type: UPDATE_ARTICLE, payload: article });
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Artículo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteArticles = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.articles.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ARTICLE, payload: Ids });
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Artículo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ARTICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
