import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_REQUEST,
    DELETE_REQUEST,
    REQUEST_FORM_TOOGLE_LOADING,
    SET_REQUEST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_REQUEST,
} from "../constants";
import { formatRequests } from "./settingsActionsUtils";

/* REQUEST LIST */
export const fetchRequests = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.requests.list)
            .then((response) => {
                const requests = formatRequests(response.data);
                dispatch({
                    type: SET_REQUEST_LIST,
                    payload: keyBy(requests, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// REQUESTS
export const createRequest = (newRequest) => {
    return async (dispatch) => {
        dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.requests.create, newRequest)
            .then((response) => {
                const request = formatRequests(response.data);
                dispatch({ type: ADD_NEW_REQUEST, payload: request });
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Solicitud de material creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRequest = (updatedRequest) => {
    return async (dispatch) => {
        dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.requests.edit}${updatedRequest && updatedRequest._id}`, updatedRequest)
            .then((response) => {
                const request = formatRequests(response.data);
                dispatch({ type: UPDATE_REQUEST, payload: request });
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Solicitud de material actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRequests = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.requests.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_REQUEST, payload: Ids });
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Solicitud de material eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: REQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
