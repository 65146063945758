import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EPIREQUEST,
    DELETE_EPIREQUEST,
    EPIREQUEST_FORM_TOOGLE_LOADING,
    SET_EPIREQUEST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EPIREQUEST,
} from "../constants";
import { formatEpiRequests } from "./settingsActionsUtils";

/* EPIREQUEST LIST */
export const fetchEpiRequests = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.epiRequests.list)
            .then((response) => {
                const epiRequests = formatEpiRequests(response.data);
                dispatch({
                    type: SET_EPIREQUEST_LIST,
                    payload: keyBy(epiRequests, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EPIREQUESTS
export const createEpiRequest = (newEpiRequest) => {
    return async (dispatch) => {
        dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.epiRequests.create, newEpiRequest)
            .then((response) => {
                const epiRequest = formatEpiRequests(response.data);
                dispatch({ type: ADD_NEW_EPIREQUEST, payload: epiRequest });
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Entrega de material creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEpiRequest = (updatedEpiRequest) => {
    return async (dispatch) => {
        dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.epiRequests.edit}${updatedEpiRequest && updatedEpiRequest._id}`, updatedEpiRequest)
            .then((response) => {
                const epiRequest = formatEpiRequests(response.data);
                dispatch({ type: UPDATE_EPIREQUEST, payload: epiRequest });
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Entrega de material actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEpiRequests = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.epiRequests.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EPIREQUEST, payload: Ids });
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Entrega de material eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPIREQUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
