import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CUSTOMER,
    DELETE_CUSTOMER,
    CUSTOMER_FORM_TOOGLE_LOADING,
    SET_CUSTOMER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CUSTOMER,
} from "../constants";
import { formatCustomers } from "./settingsActionsUtils";

/* CUSTOMER LIST */
export const fetchCustomers = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.customers.list)
            .then((response) => {
                const customers = formatCustomers(response.data);
                dispatch({
                    type: SET_CUSTOMER_LIST,
                    payload: keyBy(customers, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CUSTOMERS
export const createCustomer = (newCustomer) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.customers.create, newCustomer)
            .then((response) => {
                const customer = formatCustomers(response.data);
                dispatch({ type: ADD_NEW_CUSTOMER, payload: customer });
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Cliente creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCustomer = (updatedCustomer) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.customers.edit}${updatedCustomer && updatedCustomer._id}`, updatedCustomer)
            .then((response) => {
                const customer = formatCustomers(response.data);
                dispatch({ type: UPDATE_CUSTOMER, payload: customer });
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Cliente actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCustomers = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.customers.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CUSTOMER, payload: Ids });
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Cliente eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const sendReport = (newCUSTOMER) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.customers.mail, newCUSTOMER)
            .then((response) => {
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Enviado por correo.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};