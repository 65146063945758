import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EPI,
    DELETE_EPI,
    EPI_FORM_TOOGLE_LOADING,
    SET_EPI_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EPI,
} from "../constants";
import { formatEpis } from "./settingsActionsUtils";

/* EPI LIST */
export const fetchEpis = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.epis.list)
            .then((response) => {
                const epis = formatEpis(response.data);
                dispatch({
                    type: SET_EPI_LIST,
                    payload: keyBy(epis, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EPIS
export const createEpi = (newEpi) => {
    return async (dispatch) => {
        dispatch({ type: EPI_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.epis.create, newEpi)
            .then((response) => {
                const epi = formatEpis(response.data);
                dispatch({ type: ADD_NEW_EPI, payload: epi });
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Material creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEpi = (updatedEpi) => {
    return async (dispatch) => {
        dispatch({ type: EPI_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.epis.edit}${updatedEpi && updatedEpi._id}`, updatedEpi)
            .then((response) => {
                const epi = formatEpis(response.data);
                dispatch({ type: UPDATE_EPI, payload: epi });
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Material actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEpis = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EPI_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.epis.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EPI, payload: Ids });
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Material eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EPI_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
